import React from 'react';
import logo from './logo-xcloud.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App-title">
          XCloud Landing Page
        </div>
        <div className="App-description">
          Welcome to our landing page! Our website features the integrated Data Collection area, File Distribution area and URL Redirection area. With separate URLs for development, testing, and production versions, you can choose the environment that best suits your requirements.
        </div>
        <a
          className="App-link"
          href="https://dev-admin-euw-wsa-container.calmglacier-ab43afd1.westeurope.azurecontainerapps.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          XCloud Admin Website - DEV environment
        </a>
        <a
          className="App-link"
          href="https://tst-admin-euw-wsa-container.ambitiousmoss-14b43191.westeurope.azurecontainerapps.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          XCloud Admin Website - TST environment
        </a>
        <a
          className="App-link"
          href="https://prod-admin-euw-wsa-container.bravebeach-61a7ecc7.westeurope.azurecontainerapps.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          XCloud Admin Website - PROD environment
        </a>
        <p>/</p>
        <a
          className="App-link"
          href="https://go.wsa.com/meet-xcloud/"
          target="_blank"
          rel="noopener noreferrer"
        >
          XCloud on MS Teams
        </a>

      </header>
    </div>
  );
}

export default App;
